/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import i18n from 'utils/i18n';
import { rootStore } from 'stores/contexts/storesContext';
import { paramSerializer } from 'utils/functions';
import { User, UserExcel, UserPaging, UserPreferences } from 'models/user';
import { ActiveSensor, Sensor } from 'models/sensor';
import {
  ExtendedIProtocol,
  GrowingType,
  IProtocol,
  PhenologicalStage,
  PointerIProtocol,
} from 'models/protocol';
import {
  Grower,
  ResellerUsersPaginated,
  TreeGrower,
  ResellerTreeNode,
  GrowerSystem,
} from 'models/grower';
import {
  SystemConfigurationSchema,
  SystemSensorType,
  SystemType,
  SystemVariant,
} from 'models/systems';
import { CropType } from 'models/shared';
import { CreatePlotParams } from 'models/api/plot';
import {
  BatchCreateSensorsResponse,
  GetSystemSensorsResponse,
  SensorCreatePayload,
} from 'models/api/sensor';
import {
  PlotData,
  PlotModel,
  ServiceLevel,
  PlotExcel,
  WebPlot,
  PlotFilterResponse,
  SoilType,
} from 'models/plot';
import { CreateFeatureForm } from 'stores/featuresStore';
import veryLocalStorage from 'utils/vls';
import {
  generateAttachMultiplePlotToSensor,
  generateCreateSensorBatch,
} from 'utils/plotSensorsUtils';
import Api, { ApiEndpoints, ApiPublic, AuthApi } from './Api';

const genericApiHandler = async <T>(
  res: Promise<AxiosResponse<T>>,
  showError = true,
  returnFullReponse = false,
) => {
  try {
    const response = await res;
    if (returnFullReponse) {
      return response;
    }

    return response.data;
  } catch (error) {
    if (showError && (error instanceof Error || axios.isAxiosError(error))) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          rootStore.snackBarStore.showToast({
            detail: i18n.t<string>('errors:400'),
          });
        } else if (error.response?.status === 401) {
          rootStore.snackBarStore.showToast({
            detail: i18n.t<string>('errors:401'),
          });
        } else if (error.response?.status === 402) {
          rootStore.snackBarStore.showToast({
            detail: i18n.t<string>('errors:402'),
          });
        } else if (error.response?.status === 403) {
          rootStore.snackBarStore.showToast({
            detail: i18n.t<string>('errors:403'),
          });
        } else if (error.response?.status === 404) {
          rootStore.snackBarStore.showToast({
            detail: i18n.t<string>('errors:404'),
          });
        } else {
          rootStore.snackBarStore.showToast({ detail: error.message });
        }
        // TODO ERROR STUFF
      } else {
        rootStore.snackBarStore.showToast({ detail: error.message });
        // TODO ERROR STUFF
      }
    }

    return Promise.reject(error);
  }
};

const postLogin = async (email: string, password: string) => {
  return genericApiHandler<any>(
    Api.post(ApiEndpoints.postLogin(email, password)),
  );
};

const postRefreshToken = async (token: string) => {
  return AuthApi.post(ApiEndpoints.postRefreshToken(token));
};

const getSupportedLocales = async () => {
  return ApiPublic.get(ApiEndpoints.getSupportedLocales());
};

const postForgotPassword = async (password: string) => {
  return genericApiHandler<any>(
    Api.post(ApiEndpoints.postForgotPassword(password)),
  );
};

const postResetPassword = async (
  newPassword: string,
  email: string,
  passwordToken: string,
) => {
  return genericApiHandler<any>(
    Api.post(ApiEndpoints.postResetPassword(newPassword, email, passwordToken)),
  );
};

const postAccessOtc = async (phoneNumber: string) => {
  return AuthApi.post(ApiEndpoints.postAccessOtc(phoneNumber));
};

const postVerifyOtc = async (requestId: string, otp: string) => {
  return genericApiHandler<any>(
    AuthApi.post(ApiEndpoints.postVerifyOtc(requestId, otp)),
  );
};

const getCropProtocol = async (protocolId: string) => {
  return genericApiHandler<any>(
    Api.get(ApiEndpoints.getCropProtocol(protocolId)),
  );
};

const getAllProtocols = async () => {
  return genericApiHandler<IProtocol[]>(
    Api.get(ApiEndpoints.getAllProtocols()),
  );
};

const postUploadExcelProtocols = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return genericApiHandler<PointerIProtocol[]>(
    Api.post(ApiEndpoints.postUploadExcelProtocols(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
    false,
  );
};

const postUploadJSONProtocols = async (protocols: IProtocol[]) => {
  return genericApiHandler<ExtendedIProtocol[]>(
    Api.post(ApiEndpoints.postUploadJSONProtocols(), protocols),
  );
};

const getGrowers = () => {
  return genericApiHandler<Grower[]>(Api.get(ApiEndpoints.getGrowers()));
};

const getCropTypes = () => {
  return genericApiHandler<CropType[]>(Api.get(ApiEndpoints.getCropTypes()));
};

type GetPlotsParameters = {
  growerId?: number;
  plotId?: number;
  modelId?: string;
  offset?: number;
  cnt?: number;
  timeZone?: string[];
  minSize?: number;
  maxSize?: number;
  soilTypeId?: number[];
  serviceLevel?: string[];
};

const getPlots = (params: GetPlotsParameters) => {
  const parameters = new URLSearchParams();
  (Object.keys(params) as Array<keyof typeof params>).forEach((el) => {
    const value = params[el];
    if (value && value.toString) {
      parameters.append(el, value.toString());
    }
  });

  return genericApiHandler<PlotData>(
    Api.get(`${ApiEndpoints.getPlots()}?${parameters.toString()}`),
  );
};

const getGrowerPlots = (growerId: number, historic: boolean) => {
  return genericApiHandler<WebPlot[]>(
    Api.get(ApiEndpoints.getGrowerPlots(growerId, historic)),
  );
};

const getGrowerSystems = async (growerId: number) => {
  return genericApiHandler<GrowerSystem[]>(
    Api.get(ApiEndpoints.getGrowerSystems(growerId)),
  );
};

const getSystemTypes = async () => {
  return genericApiHandler<SystemType[]>(
    Api.get(ApiEndpoints.getSystemTypes()),
  );
};

const getSensorTypes = async () => {
  return genericApiHandler<SystemSensorType[]>(
    Api.get(ApiEndpoints.getSensorTypes()),
  );
};

const getSystemActiveSensors = async (growerId: number, systemId: number) => {
  return genericApiHandler<ActiveSensor[]>(
    Api.get(ApiEndpoints.getSystemActiveSensors(growerId, systemId)),
  );
};

const getSystemSensors = async (growerId: number, systemId: number) => {
  return genericApiHandler<Sensor[]>(
    Api.get(ApiEndpoints.getSystemSensors(growerId, systemId)),
  );
};

const getPlotSensors = async (plotId: number) => {
  return genericApiHandler<GetSystemSensorsResponse>(
    Api.get(ApiEndpoints.getPlotSensors(plotId)),
  );
};

const getGrowerSensors = async (growerId: number) => {
  return genericApiHandler<GetSystemSensorsResponse>(
    Api.get(ApiEndpoints.getGrowerSensors(growerId)),
  );
};

const getSystemValves = async (systemId: number) => {
  return genericApiHandler<string[]>(
    Api.get(ApiEndpoints.getSystemValves(systemId)),
  );
};

const putAttachMultiplePlotToSensor = async (
  plotId: number,
  sensors: Sensor[],
) => {
  const modifiedSensors = generateAttachMultiplePlotToSensor(sensors);
  return genericApiHandler<void>(
    Api.put(
      ApiEndpoints.putAttachMultiplePlotToSensor(plotId),
      modifiedSensors,
    ),
  );
};

const deleteSensorsFromPlot = async (plotId: number, sensorId: number) => {
  return genericApiHandler<void>(
    Api.delete(ApiEndpoints.deleteSensorsFromPlot(plotId, sensorId)),
  );
};

const getSystemConfigurationSchema = async (system: SystemVariant) => {
  return genericApiHandler<SystemConfigurationSchema>(
    Api.get(ApiEndpoints.getSystemConfigurationSchema(system)),
  );
};

const postCreateSystem = async (system: GrowerSystem) => {
  return genericApiHandler<GrowerSystem>(
    Api.post(ApiEndpoints.postCreateSystem(), system),
  );
};

const deleteSystem = async (systemId: number) => {
  return genericApiHandler<void>(
    Api.delete(ApiEndpoints.deleteSystem(systemId)),
  );
};

const putUpdateSystem = async (system: GrowerSystem) => {
  return genericApiHandler<GrowerSystem>(
    Api.put(ApiEndpoints.putUpdateSystem(system.id as number), system),
  );
};

const postBatchCreateSensors = async (
  growerId: number,
  systemId: number,
  sensors: SensorCreatePayload[],
) => {
  const modifiedSensors = generateCreateSensorBatch(sensors);
  return genericApiHandler<BatchCreateSensorsResponse>(
    Api.post(
      ApiEndpoints.postBatchCreateSensors(growerId, systemId),
      modifiedSensors,
    ),
  );
};

const postCreateSensor = async (growerId: number, sensorQuery: string) => {
  return genericApiHandler<Sensor>(
    Api.post(ApiEndpoints.postCreateSensor(growerId, sensorQuery)),
  );
};

const putUpdateSensor = async (sensor: Sensor) => {
  return genericApiHandler<Sensor>(
    Api.put(ApiEndpoints.putUpdateSensor(), sensor),
  );
};

const deleteSensor = async (
  growerId: number,
  systemId: number,
  sensorId: number,
) => {
  return genericApiHandler<void>(
    Api.put(ApiEndpoints.deleteSensor(growerId, systemId, sensorId)),
  );
};

const getPlotFilters = (protocolId: string) => {
  return genericApiHandler<PlotFilterResponse>(
    Api.get(ApiEndpoints.getPlotFilters(protocolId)),
  );
};

const getPlotTemplate = () => {
  return genericApiHandler<ArrayBuffer>(
    Api.get(ApiEndpoints.getPlotTemplate(), {
      responseType: 'arraybuffer',
    }),
  );
};

const putDisableUsers = (ids: number[]) => {
  return genericApiHandler<User[]>(
    Api.get(ApiEndpoints.putDisableUsers(), {
      params: { id: ids },
      paramsSerializer: paramSerializer,
    }),
  );
};

const putEnableUsers = (ids: number[]) => {
  return genericApiHandler<User[]>(
    Api.get(ApiEndpoints.putEnableUsers(), {
      params: { id: ids },
      paramsSerializer: paramSerializer,
    }),
  );
};

const deleteCropProtocol = (growerId: number, protocolId: string) => {
  return genericApiHandler<any>(
    Api.delete(ApiEndpoints.deleteCropProtocol(growerId, protocolId)),
  );
};

// Users
const getAllUsers = () => {
  return genericApiHandler<UserPaging>(Api.get(ApiEndpoints.getAllUsers()));
};

const getAllCompanyRoles = () => {
  return genericApiHandler<any>(Api.get(ApiEndpoints.getAllCompanyRoles()));
};

const postUser = (user: User) => {
  return genericApiHandler<User>(
    Api.post(ApiEndpoints.postUser(), user),
    false,
  );
};

const putUsers = (users: User[]) => {
  return genericApiHandler<any>(Api.put(ApiEndpoints.putUsers(), users));
};

const getUserPreferences = () => {
  return genericApiHandler<UserPreferences[]>(
    Api.get(ApiEndpoints.getUserPreferences()),
  );
};

const deleteUsers = (usersId: Array<number>) => {
  return genericApiHandler<any>(
    Api.delete(ApiEndpoints.deleteUsers(), {
      params: { userIds: usersId },
      paramsSerializer: paramSerializer,
    }),
  );
};

const postUploadUsersExcel = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return genericApiHandler<UserExcel[]>(
    Api.post(ApiEndpoints.postUploadUsersExcel(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
    false,
  );
};

const postCreateUsers = async (users: UserExcel[]) => {
  return genericApiHandler<any>(
    Api.post(ApiEndpoints.postCreateUsers(), users, {
      responseType: 'blob',
    }),
    false,
  );
};

const getUsersExcelTemplate = () => {
  return genericApiHandler<ArrayBuffer>(
    Api.get(ApiEndpoints.getUsersExcelTemplate(), {
      responseType: 'arraybuffer',
    }),
  );
};

// UserToGrower Relationship
const getUserGrowers = (
  userId: number,
  offset = 0,
  cnt = 100,
  isAssigned = true,
) => {
  return genericApiHandler<any>(
    Api.get(ApiEndpoints.getUserGrowers(userId, offset, cnt, isAssigned)),
  );
};

const getUserGrowersRoles = (userId: number) => {
  return genericApiHandler<any>(
    Api.get(ApiEndpoints.getUserGrowersRoles(userId)),
  );
};

const putUserGrowersRole = (
  userId: number,
  roleId: number,
  growersIds: Array<number>,
) => {
  return genericApiHandler<any>(
    Api.put(ApiEndpoints.putUserGrowersRole(userId, roleId), growersIds),
  );
};

const deleteUnassignUserGrowers = (
  userId: number,
  growersIds: Array<number>,
) => {
  return genericApiHandler<any>(
    Api.delete(ApiEndpoints.deleteUnassignUserGrowers(userId), {
      data: growersIds,
    }),
  );
};

const putAssignUserGrowers = (
  userId: number,
  roleId: number,
  growersIds: Array<number>,
) => {
  return genericApiHandler<any>(
    Api.put(ApiEndpoints.putAssignUserGrowers(userId, roleId), growersIds),
  );
};

// Reseller Page
const getAllGrowers = async () => {
  return genericApiHandler<ResellerTreeNode[]>(
    Api.get(ApiEndpoints.getAllGrowers()),
  );
};

const postGrower = (grower: ResellerTreeNode) => {
  return genericApiHandler<TreeGrower>(
    Api.post(ApiEndpoints.postGrower(), grower),
    false,
  );
};

const putGrowers = (growers: TreeGrower[]) => {
  return genericApiHandler<void>(Api.put(ApiEndpoints.putGrowers(), growers));
};

const putUpdateGrowerStatus = (growerId: number, status: boolean) => {
  return genericApiHandler<void>(
    Api.put(ApiEndpoints.putUpdateGrowerStatus(growerId, status)),
  );
};

const deleteGrowers = (growers: TreeGrower[]) => {
  return genericApiHandler<any>(
    Api.delete(ApiEndpoints.deleteGrowers(), {
      data: growers,
    }),
  );
};

const getResellerUsers = async (growerId: number) => {
  return genericApiHandler<ResellerUsersPaginated>(
    Api.get(ApiEndpoints.getResellerUsers(growerId)),
  );
};

const getUserNotification = async (userID: number) => {
  return genericApiHandler<any>(
    Api.get(ApiEndpoints.getUserNotification(userID)),
  );
};

const putMoveGrowerToReseller = async (
  growerId?: number,
  growersId?: number[],
) => {
  return genericApiHandler<any>(
    Api.put(ApiEndpoints.putMoveGrowerToReseller(growerId), growersId),
  );
};

const getResellerPlots = async (resellerId: number, historic: boolean) => {
  return genericApiHandler<WebPlot[]>(
    Api.get(ApiEndpoints.getResellerPlots(resellerId, historic)),
  );
};

const postUploadPlotsExcel = async (growerId: number | null, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return genericApiHandler<PlotExcel[]>(
    Api.post(ApiEndpoints.postUploadPlotsExcel(growerId), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
    false,
  );
};

const postCreatePlotsFromJson = async (
  growerId: number | null,
  plots: PlotExcel[],
) => {
  return genericApiHandler<any>(
    Api.post(ApiEndpoints.postCreatePlotsFromJson(growerId), plots, {
      responseType: 'blob',
    }),
    false,
  );
};

// Create plot
const getGrowingTypes = async () => {
  return genericApiHandler<GrowingType[]>(
    Api.get(ApiEndpoints.getGrowingTypes()),
  );
};

const getSoilTypes = async () => {
  return genericApiHandler<SoilType[]>(Api.get(ApiEndpoints.getSoilTypes()));
};

const getServiceLevels = async () => {
  return genericApiHandler<ServiceLevel[]>(
    Api.get(ApiEndpoints.getServiceLevels()),
  );
};

const getIrrigationMethods = async () => {
  return genericApiHandler<string[]>(
    Api.get(ApiEndpoints.getIrrigationMethods()),
  );
};

const getGrowerCropTypes = async (growerId: number) => {
  return genericApiHandler<CropType[]>(
    Api.get(ApiEndpoints.getGrowerCropTypes(growerId)),
  );
};

const getCropCategories = async (
  growerId: number | null,
  cropId: number | null,
) => {
  return genericApiHandler<string[]>(
    Api.get(ApiEndpoints.getCropCategories(growerId, cropId)),
  );
};

const getCropVarieties = async (
  growerId: number | null,
  cropId: number | null,
) => {
  return genericApiHandler<string[]>(
    Api.get(ApiEndpoints.getCropVarieties(growerId, cropId)),
  );
};

const getCropProtocolId = async (
  growerId: number | null,
  cropId: number | null,
  latitude: number,
  longitude: number,
  variety: string,
  category: string,
) => {
  return genericApiHandler<any>(
    Api.get(
      ApiEndpoints.getCropProtocolId(
        growerId,
        cropId,
        latitude,
        longitude,
        variety,
        category,
      ),
    ),
  );
};

const postCreatePlot = (
  growerId: number | null,
  plot: PlotModel,
  plotParams: CreatePlotParams,
) => {
  return genericApiHandler<any>(
    Api.post(ApiEndpoints.postCreatePlot(growerId), plot, {
      params: plotParams,
    }),
  );
};

const getPhenologicalStages = async (
  protocolId: string | null,
  plantDate: number | null,
  plotId: number | null,
) => {
  return genericApiHandler<PhenologicalStage[]>(
    Api.get(ApiEndpoints.getPhenologicalStages(protocolId, plantDate, plotId)),
  );
};

const getGrowingMethods = async (
  protocolId: string | null,
  plantDate: number | null,
  plotId: number | null,
) => {
  return genericApiHandler<string[]>(
    Api.get(ApiEndpoints.getGrowingMethods(protocolId, plantDate, plotId)),
  );
};

const putUpdatePlot = async (
  growerId: number | null,
  plotId: number | null,
  plot: PlotModel,
) => {
  return genericApiHandler<WebPlot>(
    Api.put(ApiEndpoints.putUpdatePlot(growerId, plotId), plot),
  );
};

const putUpdatePlotsStatus = (growerId: number | null, plots: WebPlot[]) => {
  return genericApiHandler<any>(
    Api.put(ApiEndpoints.putUpdatePlotsStatus(growerId), plots),
  );
};

const getFeaturesSchema = async () => {
  return genericApiHandler<any>(Api.get(ApiEndpoints.getFeaturesSchema()));
};

const getPlotFeatureSensors = async (plotId: number) => {
  return genericApiHandler<any>(Api.get(ApiEndpoints.getPlotSensors(plotId)));
};

const postFeature = (feature: CreateFeatureForm) => {
  return genericApiHandler<any>(
    Api.post(
      ApiEndpoints.postFeature(feature.plotId, feature.mlModelId?.name || ''),
      feature,
    ),
    false,
  );
};

const getFeaturesByGrower = (growerId: number) => {
  return genericApiHandler<any>(
    Api.get(ApiEndpoints.getFeaturesByGrower(growerId)),
  );
};

const deleteFeature = async (
  plotId: number | null,
  featureId: number | null,
) => {
  return genericApiHandler<void>(
    Api.delete(ApiEndpoints.deleteFeature(plotId, featureId)),
  );
};

const getRunAnomalyDetection = (growerId: string | number) => {
  return genericApiHandler<any>(
    Api.get(ApiEndpoints.getRunAnomalyDetection(growerId)),
    true,
    true,
  );
};

const api = {
  postLogin,
  postRefreshToken,
  postForgotPassword,
  postResetPassword,
  postAccessOtc,
  postVerifyOtc,
  getCropProtocol,
  getAllProtocols,
  postUploadExcelProtocols,
  postUploadJSONProtocols,
  getGrowers,
  getCropTypes,
  getPlots,
  getGrowerPlots,
  getGrowerSystems,
  getSystemTypes,
  getSensorTypes,
  getSystemActiveSensors,
  getSystemSensors,
  getPlotSensors,
  getGrowerSensors,
  getSystemValves,
  putAttachMultiplePlotToSensor,
  deleteSensorsFromPlot,
  getSystemConfigurationSchema,
  postCreateSystem,
  deleteSystem,
  putUpdateSystem,
  postCreateSensor,
  postBatchCreateSensors,
  putUpdateSensor,
  deleteSensor,
  getPlotFilters,
  getAllUsers,
  deleteUsers,
  getAllGrowers,
  getResellerUsers,
  getUserNotification,
  putDisableUsers,
  putEnableUsers,
  getAllCompanyRoles,
  getUserGrowers,
  getUserGrowersRoles,
  putUserGrowersRole,
  deleteUnassignUserGrowers,
  putAssignUserGrowers,
  putUsers,
  postUser,
  getUserPreferences,
  deleteGrowers,
  putGrowers,
  putUpdateGrowerStatus,
  putMoveGrowerToReseller,
  postGrower,
  getResellerPlots,
  deleteCropProtocol,
  getGrowingTypes,
  getSoilTypes,
  getServiceLevels,
  getIrrigationMethods,
  getGrowerCropTypes,
  getCropCategories,
  getCropVarieties,
  getCropProtocolId,
  postCreatePlot,
  getPhenologicalStages,
  getGrowingMethods,
  putUpdatePlot,
  postUploadPlotsExcel,
  postCreatePlotsFromJson,
  putUpdatePlotsStatus,
  getPlotTemplate,
  getFeaturesSchema,
  getPlotFeatureSensors,
  postFeature,
  getFeaturesByGrower,
  deleteFeature,
  postUploadUsersExcel,
  postCreateUsers,
  getUsersExcelTemplate,
  getSupportedLocales,
  getRunAnomalyDetection,
};

export default api;
