import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  InputLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from 'components/UI/Button';
import Dialog from 'components/UI/Dialog';
import Grid from 'components/UI/Grid';
import { VisibilityControl } from 'components/shared/VisibilityControl';
import { DEFAULT_COMPANY_OPTIONS } from 'core/constants';
import { useStores } from 'stores/hooks/hooks';
import { ActionMode } from 'models/shared';

const StyledDialogContent = styled(DialogContent)`
  padding: 1rem;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 0 1rem 0.5rem;
`;

const sensorAdditionalFieldsSchema = z.object({
  altitude: z.number().optional(),
  depth: z.number().optional(),
  companyUuid: z.string().optional(),
  valveId: z.string().optional(),
  alias: z.string().optional(),
});

export type EditableRowParamsValues = z.infer<
  typeof sensorAdditionalFieldsSchema
>;

interface EditTableRowDialogProps extends Pick<DialogProps, 'title'> {
  onSubmit(data: EditableRowParamsValues): void;
  onClose(): void;
  defaultValues: EditableRowParamsValues;
  isValveFieldVisible: boolean;
  isCompanyFieldVisible: boolean;
  disabled?: boolean;
  mode?: ActionMode;
}

const EditTableRowDialog: FC<EditTableRowDialogProps> = observer(
  ({
    title,
    onSubmit,
    onClose,
    defaultValues,
    isValveFieldVisible,
    isCompanyFieldVisible,
    mode,
    disabled,
  }) => {
    const { t } = useTranslation('system');
    const { systemsStore } = useStores();
    const { register, control, getValues } = useForm<EditableRowParamsValues>({
      resolver: zodResolver(sensorAdditionalFieldsSchema),
      mode: 'onChange',
      defaultValues,
    });

    const handleSubmitClick = useCallback(() => {
      const values = getValues();
      const validValues = Object.fromEntries(
        Object.entries(values).filter(
          ([, value]) => !Number.isNaN(value) && !!value,
        ),
      );

      onSubmit(validValues);
    }, [onSubmit, getValues]);

    return (
      <Dialog
        open
        fullWidth
        disableEscapeKeyDown
        dialogTitle={title}
        maxWidth="sm"
        titleWeight="bold"
        onClose={onClose}
      >
        <StyledDialogContent>
          <Grid direction="column" align="stretch" gap="1rem">
            <VisibilityControl visible={isCompanyFieldVisible}>
              <Controller
                control={control}
                name="companyUuid"
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('company_uuid')}</InputLabel>
                    <Select
                      {...register('companyUuid')}
                      label={t('company_uuid')}
                      value={value}
                      disabled={disabled}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                      {DEFAULT_COMPANY_OPTIONS?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </VisibilityControl>
            <TextField
              {...register('altitude', { valueAsNumber: true })}
              label={t('altitude')}
              type="number"
              disabled={mode === ActionMode.EDIT ? true : disabled}
            />
            <TextField
              {...register('depth', { valueAsNumber: true })}
              label={t('depth')}
              type="number"
              disabled={mode === ActionMode.EDIT ? true : disabled}
            />
            <TextField
              {...register('alias', { valueAsNumber: false })}
              label={t('alias')}
              type="text"
              disabled={disabled}
            />
            <VisibilityControl visible={isValveFieldVisible}>
              <Controller
                control={control}
                name="valveId"
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('valve_id')}</InputLabel>
                    <Select
                      {...register('valveId')}
                      label={t('valve_id')}
                      value={value}
                      disabled={disabled}
                      onChange={(event) => {
                        onChange(event.target.value);
                      }}
                    >
                      {systemsStore.systemValves.data.map((valve) => (
                        <MenuItem key={valve} value={valve}>
                          {valve}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </VisibilityControl>
          </Grid>
        </StyledDialogContent>
        {!disabled && (
          <StyledDialogActions>
            <Button color="primary" onClick={handleSubmitClick}>
              {i18next.t('general:save')}
            </Button>
          </StyledDialogActions>
        )}
      </Dialog>
    );
  },
);

export default EditTableRowDialog;
